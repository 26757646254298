.account {
  .profileInfoWrap {
    background: #fff;
    padding: 30px;
    text-align: center;
    margin-bottom: 32px;
    border-radius: 4px;
  }
  .profileInfoWrap .profileInfoImg {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .profileInfoContent {
    margin: auto;
  }
  .profileTabMenu {
    background: #fff;
    padding: 30px;
    text-align: center;
    overflow: visible;
    border-radius: 4px;
  }

  .profileTab {
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
    align-items: center;
  }
  .profileTabMenu p {
    font-size: 14px;
  }

  .icon {
    margin-right: 7px;
    font-size: 25;
  }

  .active {
    color: #44b700 !important;

    .icon {
      color: #44b700 !important;
    }
  }

  .profileTab:hover {
    color: #44b700 !important;
    .icon {
      color: #44b700 !important;
    }
    p {
      font-size: 17px;
    }
  }

  .profile {
    span {
      font-size: 14px;
    }

    .profileInput {
      background-color: #fafafa;
      width: 292px;
      height: 56px;
    }

    .profileInput p {
      position: absolute;
      margin-left: 8px;
      font-weight: bold;
    }
  }
}
