.icon {
  font-size: 30px;
}

a {
  text-decoration: none;
  color: #344767;
}

// a:hover {
//   background-color: #d1e9fc;
//   color: red;
// }
// a:active {
//   background-color: #d1e9fc;
//   color: red;
// }

.icon:active {
  background-color: red;
}

.logout {
  color: #ffff;
}
