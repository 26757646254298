.css-1jxwo5f-MuiPaper-root-MuiCard-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
  box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
  background: linear-gradient(
    310deg,
    rgba(20, 23, 39, 0.8),
    rgba(58, 65, 111, 0.8)
  );
  box-shadow: 0rem 1.4375rem 2.8125rem -0.6875rem rgba(20, 20, 20, 0.25);
}
.css-9ksg9q {
  padding: 16px;
  opacity: 1;
  background: transparent;
  //   color: #344767;
  border-radius: none;
}
.css-irezhe {
  padding: 8px;
  line-height: 0;
  display: inline-block;
  opacity: 1;
  background: transparent;
  color: #ffffff;
  border-radius: none;
}
.css-f03h9h-MuiTypography-root {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.375;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  //   color: #344767;
  font-weight: 500;
  letter-spacing: 0em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 40px;
  padding-bottom: 8px;
}
.css-7qe4b4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  background: transparent;
  //   color: #344767;
  border-radius: none;
}
.css-1vv3gdn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  background: transparent;
  //   color: #344767;
  border-radius: none;
}
.css-1mrsybz {
  margin-right: 24px;
  line-height: 1;
  opacity: 1;
  background: transparent;
  color: #ffffff;
  border-radius: none;
}
.css-uujcwg {
  line-height: 1;
  opacity: 1;
  background: transparent;
  color: #ffffff;
  border-radius: none;
}
.css-hxcye6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  width: 20%;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
}
.css-14lun21 {
  width: 60%;
  margin-top: 8px;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
}
.bb {
  font-weight: 500 !important;
}
