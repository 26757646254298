.accountInfo {
  background-color: rgba(145, 158, 171, 0.12);
  display: flex;
  align-items: center;
  border-radius: 9px;
}

.accountInfo {
  //   margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}
.icon {
  color: #637381;
  width: 20px !important;
}
.css-r6ewbb-MuiToolbar-root {
  justify-content: flex-end !important;
}

.welcomeText {
  font-size: 1.1rem;
  font-family: Public sans, sans-serif;
  margin-top: auto;
}
.balanceContainer {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  //   overflow: hidden;
  border-radius: 12px !important;
  //   padding-bottom: 40px;
  box-shadow: none !important;
  //   text-align: center;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px !important;
}

// .paddingall {
//   padding: 10px 10px 10px 10px;
// }
