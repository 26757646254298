
  @charset "UTF-8";
  ._acsbottomleft._acsreport--default ._acsBadgeLabel,
  ._acsbottomright._acsreport--default ._acsBadgeLabel,
  ._acsmiddleleft._acsreport--default ._acsBadgeLabel,
  ._acsmiddleright._acsreport--default ._acsBadgeLabel,
  ._acstopleft._acsreport--default ._acsBadgeLabel,
  ._acstopright._acsreport--default ._acsBadgeLabel {
    padding-right: 0.3em;
  }
  ._acsbadge--default,
  ._acsreport--default {
    z-index: 2000201;
    cursor: pointer;
    white-space: nowrap;
  }
  .acsMainContainerFullPage--default .verint-sdk-star-rating label:focus,
  .acsMainContainerMobile--default .verint-sdk-star-rating label:focus,
  .acsModalContainer--default .acsModalChrome .acsModalContent :focus,
  .acsModalContainer--default .verint-sdk-star-rating label:focus {
    outline-style: solid !important;
  }
  .acs-feedback__forcefont {
    display: none;
    font-family: "Material Icons - Verint No Conflict";
  }
  ._acsbadge--default ._acsBadgeLabel,
  ._acsbadge--default ._acsCounter ._acsCounterInner,
  ._acsreport--default ._acsBadgeLabel,
  ._acsreport--default ._acsCounter ._acsCounterInner {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  ._acs ._acsNoDisplay {
    display: none !important;
  }
  @font-face {
    font-family: "Material Icons - Verint No Conflict";
    font-style: normal;
    font-weight: 400;
  }
  ._acsreport--default {
    position: fixed;
    -webkit-transform: translateZ(0);
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    -webkit-box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.4);
    font-size: 14px;
    border-radius: 0.25em;
    background-color: #343441;
  }
  ._acsbottomright._acsreport--default {
    margin-right: 0.6em;
    left: auto !important;
    right: 0 !important;
    top: auto;
    bottom: 0.6em;
  }
  ._acsbottomright._acsVertical_right._acsreport--default {
    -webkit-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -ms-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -moz-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    margin-bottom: -2.5em;
  }
  ._acsbottomright._acsVertical_right._acsHover._acsreport--default {
    -webkit-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    -ms-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    -moz-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
  }
  ._acsbottomleft._acsreport--default {
    right: auto !important;
    margin-left: 0.6em;
    top: auto;
    bottom: 1.2em;
  }
  ._acsbottomleft._acsVertical_left._acsreport--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    margin-bottom: -2.5em;
  }
  ._acsbottomleft._acsVertical_left._acsHover._acsreport--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
  }
  ._acstopright._acsreport--default {
    margin-right: 0.6em;
    left: auto !important;
    right: 0 !important;
    top: 0.6em;
    bottom: auto;
  }
  ._acstopright._acsVertical_right._acsreport--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    margin-top: -2.5em;
  }
  ._acstopright._acsVertical_right._acsHover._acsreport--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
  }
  ._acstopleft._acsreport--default {
    right: auto !important;
    margin-left: 0.6em;
    top: 0.6em;
    bottom: auto;
  }
  ._acstopleft._acsVertical_left._acsreport--default {
    -webkit-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -ms-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -moz-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
    margin-top: -2.5em;
  }
  ._acstopleft._acsVertical_left._acsHover._acsreport--default {
    -webkit-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    -ms-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    -moz-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
  }
  ._acsmiddleleft._acsreport--default {
    right: auto !important;
    margin-left: 0.6em;
    top: 48% !important;
  }
  ._acsmiddleleft._acsVertical_left._acsreport--default {
    -webkit-transform: rotate(-90deg) translate(-50%, 0) scale(1, 1);
    -ms-transform: rotate(-90deg) translate(-50%, 0) scale(1, 1);
    -moz-transform: rotate(-90deg) translate(-50%, 0) scale(1, 1);
    transform: rotate(-90deg) translate(-50%, 0) scale(1, 1);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    top: 50% !important;
  }
  ._acsmiddleleft._acsVertical_left._acsHover._acsreport--default {
    -webkit-transform: rotate(-90deg) translate(-50%, 0) scale(1.1, 1.1);
    -ms-transform: rotate(-90deg) translate(-50%, 0) scale(1.1, 1.1);
    -moz-transform: rotate(-90deg) translate(-50%, 0) scale(1.1, 1.1);
    transform: rotate(-90deg) translate(-50%, 0) scale(1.1, 1.1);
  }
  ._acsmiddleright._acsreport--default {
    margin-right: 0.6em;
    left: auto !important;
    right: 0 !important;
    top: 48% !important;
  }
  ._acsmiddleright._acsVertical_right._acsreport--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    top: 30% !important;
  }
  ._acsmiddleright._acsVertical_right._acsHover._acsreport--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
  }
  @media print {
    ._acsreport--default {
      display: none !important;
    }
  }
  ._acsbadge--small._acsreport--default {
    padding: 0.4em;
  }
  ._acsbadge--small._acsreport--default ._acsBadgeImg {
    max-height: 0.86em;
    max-width: 0.86em;
  }
  ._acsbadge--small._acsreport--default ._acsBadgeLabel {
    font-size: 0.86em;
    padding-left: 0.3em;
    padding-right: 1em;
  }
  ._acsbadge--medium._acsreport--default {
    padding: 0.7em;
  }
  ._acsbadge--medium._acsreport--default ._acsBadgeImg {
    max-height: 1em;
    max-width: 1em;
  }
  ._acsbadge--medium._acsreport--default ._acsBadgeLabel {
    font-size: 1em;
    padding-left: 0.3em;
    padding-right: 1em;
  }
  ._acsbadge--large._acsreport--default {
    padding: 0.8em;
  }
  ._acsbadge--large._acsreport--default ._acsBadgeImg {
    max-height: 1.286em;
    max-width: 1.286em;
  }
  ._acsbadge--large._acsreport--default ._acsBadgeLabel {
    font-size: 1.286em;
    padding-left: 0.3em;
    padding-right: 1em;
  }
  ._acsbottomleft._acsbadge--default ._acsBadgeLabel,
  ._acsbottomright._acsbadge--default ._acsBadgeLabel,
  ._acsmiddleleft._acsbadge--default ._acsBadgeLabel,
  ._acsmiddleright._acsbadge--default ._acsBadgeLabel,
  ._acstopleft._acsbadge--default ._acsBadgeLabel,
  ._acstopright._acsbadge--default ._acsBadgeLabel {
    padding-right: 0.3em;
  }
  ._acsAnimate._acsreport--default {
    transition-duration: 0.3s;
    opacity: 1;
  }
  @media \0screen\,
    screen\9,
    all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    ._acsAnimate._acsreport--default {
      transition-duration: 0s;
    }
  }
  @supports (-ms-ime-align: auto) {
    ._acsAnimate._acsreport--default {
      transition-duration: 0s;
    }
  }
  ._acsFixed._acsreport--default {
    position: absolute;
  }
  ._acsHover._acsbottomright._acsreport--default,
  ._acsHover._acsmiddleright._acsreport--default,
  ._acsHover._acstopright._acsreport--default {
    -webkit-transform: rotate(0) translate(-5%, 0) scale(1.1, 1.1);
    -ms-transform: rotate(0) translate(-5%, 0) scale(1.1, 1.1);
    -moz-transform: rotate(0) translate(-5%, 0) scale(1.1, 1.1);
    transform: rotate(0) translate(-5%, 0) scale(1.1, 1.1);
  }
  ._acsHover._acsbottomleft._acsreport--default,
  ._acsHover._acsmiddleleft._acsreport--default,
  ._acsHover._acstopleft._acsreport--default {
    -webkit-transform: rotate(0) translate(5%, 0) scale(1.1, 1.1);
    -ms-transform: rotate(0) translate(5%, 0) scale(1.1, 1.1);
    -moz-transform: rotate(0) translate(5%, 0) scale(1.1, 1.1);
    transform: rotate(0) translate(5%, 0) scale(1.1, 1.1);
  }
  ._acsreport--default ._acsBadgeImg {
    display: inline;
    vertical-align: middle;
    margin-bottom: 0;
  }
  ._acsreport--default ._acsCounter {
    vertical-align: middle;
  }
  ._acsreport--default ._acsCounter ._acsCounterInner {
    background-color: #ef3024;
    color: #fff;
    padding-left: 0.3em;
    padding-right: 0.3em;
    border-radius: 1em;
  }
  ._acsreport--default ._acsBadgeLabel {
    vertical-align: middle;
    position: relative;
    top: -0.04em;
  }
  ._acsbadge--default {
    position: fixed;
    -webkit-transform: translateZ(0);
    top: 0;
    left: 0;
    display: block;
    opacity: 0;
    -webkit-box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.4);
    font-size: 14px;
    background-color: #ef3024;
    border-radius: 0.25em;
  }
  ._acsbottomright._acsbadge--default {
    margin-right: 0.6em;
    left: auto !important;
    right: 0 !important;
    top: auto;
    bottom: 0.6em;
  }
  ._acsbottomright._acsVertical_right._acsbadge--default {
    -webkit-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -ms-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -moz-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    margin-bottom: -2.5em;
  }
  ._acsbottomright._acsVertical_right._acsHover._acsbadge--default {
    -webkit-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    -ms-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    -moz-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
  }
  ._acsbottomleft._acsbadge--default {
    right: auto !important;
    margin-left: 0.6em;
    top: auto;
    bottom: 1.2em;
  }
  ._acsbottomleft._acsVertical_left._acsbadge--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    margin-bottom: -2.5em;
  }
  ._acsbottomleft._acsVertical_left._acsHover._acsbadge--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
  }
  ._acstopright._acsbadge--default {
    margin-right: 0.6em;
    left: auto !important;
    right: 0 !important;
    top: 0.6em;
    bottom: auto;
  }
  ._acstopright._acsVertical_right._acsbadge--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    margin-top: -2.5em;
  }
  ._acstopright._acsVertical_right._acsHover._acsbadge--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
  }
  ._acstopleft._acsbadge--default {
    right: auto !important;
    margin-left: 0.6em;
    top: 0.6em;
    bottom: auto;
  }
  ._acstopleft._acsVertical_left._acsbadge--default {
    -webkit-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -ms-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -moz-transform: rotate(90deg) translate(0, 0) scale(1, 1);
    transform: rotate(90deg) translate(0, 0) scale(1, 1);
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
    margin-top: -2.5em;
  }
  ._acstopleft._acsVertical_left._acsHover._acsbadge--default {
    -webkit-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    -ms-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    -moz-transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
    transform: rotate(90deg) translate(0, 0) scale(1.1, 1.1);
  }
  ._acsmiddleleft._acsbadge--default {
    right: auto !important;
    margin-left: 0.6em;
    top: 48% !important;
  }
  ._acsmiddleleft._acsVertical_left._acsbadge--default {
    -webkit-transform: rotate(-90deg) translate(-50%, 0) scale(1, 1);
    -ms-transform: rotate(-90deg) translate(-50%, 0) scale(1, 1);
    -moz-transform: rotate(-90deg) translate(-50%, 0) scale(1, 1);
    transform: rotate(-90deg) translate(-50%, 0) scale(1, 1);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    top: 50% !important;
  }
  ._acsmiddleleft._acsVertical_left._acsHover._acsbadge--default {
    -webkit-transform: rotate(-90deg) translate(-50%, 0) scale(1.1, 1.1);
    -ms-transform: rotate(-90deg) translate(-50%, 0) scale(1.1, 1.1);
    -moz-transform: rotate(-90deg) translate(-50%, 0) scale(1.1, 1.1);
    transform: rotate(-90deg) translate(-50%, 0) scale(1.1, 1.1);
  }
  ._acsmiddleright._acsbadge--default {
    margin-right: 0.6em;
    left: auto !important;
    right: 0 !important;
    top: 48% !important;
  }
  ._acsmiddleright._acsVertical_right._acsbadge--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    transform: rotate(-90deg) translate(0, 0) scale(1, 1);
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    top: 30% !important;
  }
  ._acsmiddleright._acsVertical_right._acsHover._acsbadge--default {
    -webkit-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -ms-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    -moz-transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
    transform: rotate(-90deg) translate(0, 0) scale(1.1, 1.1);
  }
  @media print {
    ._acsbadge--default {
      display: none !important;
    }
  }
  ._acsbadge--small._acsbadge--default {
    padding: 0.4em;
  }
  ._acsbadge--small._acsbadge--default ._acsBadgeImg {
    max-height: 0.86em;
    max-width: 0.86em;
  }
  ._acsbadge--small._acsbadge--default ._acsBadgeLabel {
    font-size: 0.86em;
    padding-left: 0.3em;
    padding-right: 1em;
  }
  ._acsbadge--medium._acsbadge--default {
    padding: 0.7em;
  }
  ._acsbadge--medium._acsbadge--default ._acsBadgeImg {
    max-height: 1em;
    max-width: 1em;
  }
  ._acsbadge--medium._acsbadge--default ._acsBadgeLabel {
    font-size: 1em;
    padding-left: 0.3em;
    padding-right: 1em;
  }
  ._acsbadge--large._acsbadge--default {
    padding: 0.8em;
  }
  ._acsbadge--large._acsbadge--default ._acsBadgeImg {
    max-height: 1.286em;
    max-width: 1.286em;
  }
  ._acsbadge--large._acsbadge--default ._acsBadgeLabel {
    font-size: 1.286em;
    padding-left: 0.3em;
    padding-right: 1em;
  }
  ._acsAnimate._acsbadge--default {
    transition-duration: 0.3s;
    opacity: 1;
  }
  @media \0screen\,
    screen\9,
    all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    ._acsAnimate._acsbadge--default {
      transition-duration: 0s;
    }
  }
  @supports (-ms-ime-align: auto) {
    ._acsAnimate._acsbadge--default {
      transition-duration: 0s;
    }
  }
  ._acsFixed._acsbadge--default {
    position: absolute;
  }
  ._acsHover._acsbottomright._acsbadge--default,
  ._acsHover._acsmiddleright._acsbadge--default,
  ._acsHover._acstopright._acsbadge--default {
    -webkit-transform: rotate(0) translate(-5%, 0) scale(1.1, 1.1);
    -ms-transform: rotate(0) translate(-5%, 0) scale(1.1, 1.1);
    -moz-transform: rotate(0) translate(-5%, 0) scale(1.1, 1.1);
    transform: rotate(0) translate(-5%, 0) scale(1.1, 1.1);
  }
  ._acsHover._acsbottomleft._acsbadge--default,
  ._acsHover._acsmiddleleft._acsbadge--default,
  ._acsHover._acstopleft._acsbadge--default {
    -webkit-transform: rotate(0) translate(5%, 0) scale(1.1, 1.1);
    -ms-transform: rotate(0) translate(5%, 0) scale(1.1, 1.1);
    -moz-transform: rotate(0) translate(5%, 0) scale(1.1, 1.1);
    transform: rotate(0) translate(5%, 0) scale(1.1, 1.1);
  }
  ._acsbadge--default ._acsBadgeImg {
    display: inline;
    vertical-align: middle;
    margin-bottom: 0;
  }
  ._acsbadge--default ._acsCounter {
    vertical-align: middle;
  }
  ._acsbadge--default ._acsCounter ._acsCounterInner {
    background-color: #ef3024;
    color: #fff;
    padding-left: 0.3em;
    padding-right: 0.3em;
    border-radius: 1em;
  }
  ._acsbadge--default ._acsBadgeLabel {
    vertical-align: middle;
    position: relative;
    top: -0.04em;
  }
  html.fsfb.fsfb-relbody body {
    position: relative !important;
  }
  .acsFrameContainer,
  .acsFrameContainer--default {
    width: 700px;
    height: 100%;
    position: fixed;
    top: 0;
    right: -700px;
    background-color: #343941;
    border: none;
    padding: 0;
    margin: 0;
    z-index: 999999999;
    transition: box-shadow 0.5s, right 0.5s;
    font-family: "Material Icons - Verint No Conflict";
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    -webkit-box-shadow: -9px 2px 205px 103px transparent;
    -moz-box-shadow: -9px 2px 205px 103px transparent;
    box-shadow: -9px 2px 205px 103px transparent;
  }
  .acsFrameContainer.acsVisibleFrame,
  .acsVisibleFrame.acsFrameContainer--default {
    right: 0;
    -webkit-box-shadow: -9px 2px 205px 103px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -9px 2px 205px 103px rgba(0, 0, 0, 0.5);
    box-shadow: -9px 2px 205px 103px rgba(0, 0, 0, 0.5);
  }
  .acsFrameContainer.acsVisibleFrame .acsReportCloseBtn,
  .acsVisibleFrame.acsFrameContainer--default .acsReportCloseBtn {
    display: block;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
  }
  .acsFrameContainer .acsReportFrame,
  .acsFrameContainer--default .acsReportFrame {
    width: 700px;
    height: 100%;
    display: block;
    border: none;
    padding: 0;
    margin: 0;
  }
  .acsFrameContainer .acsReportCloseBtn,
  .acsFrameContainer--default .acsReportCloseBtn {
    position: absolute;
    width: 18px;
    left: -28px;
    font-size: 20px !important;
    text-decoration: none;
    font-weight: 400;
    top: 10px;
    padding: 5px 3px 5px 7px;
    background-color: #343941;
    color: #fff;
    -webkit-border-top-left-radius: 0.25em;
    -webkit-border-bottom-left-radius: 0.25em;
    -moz-border-radius-topleft: 0.25em;
    -moz-border-radius-bottomleft: 0.25em;
    border-top-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
    cursor: pointer;
    display: none;
  }
  .acsFrameContainer .acsReportCloseBtn:hover,
  .acsFrameContainer--default .acsReportCloseBtn:hover {
    color: #ccc;
  }
  .acsMainContainerFullPage--default a,
  .acsMainContainerFullPage--default abbr,
  .acsMainContainerFullPage--default acronym,
  .acsMainContainerFullPage--default address,
  .acsMainContainerFullPage--default applet,
  .acsMainContainerFullPage--default article,
  .acsMainContainerFullPage--default aside,
  .acsMainContainerFullPage--default audio,
  .acsMainContainerFullPage--default b,
  .acsMainContainerFullPage--default big,
  .acsMainContainerFullPage--default blockquote,
  .acsMainContainerFullPage--default canvas,
  .acsMainContainerFullPage--default caption,
  .acsMainContainerFullPage--default center,
  .acsMainContainerFullPage--default cite,
  .acsMainContainerFullPage--default code,
  .acsMainContainerFullPage--default dd,
  .acsMainContainerFullPage--default del,
  .acsMainContainerFullPage--default details,
  .acsMainContainerFullPage--default dfn,
  .acsMainContainerFullPage--default div,
  .acsMainContainerFullPage--default dl,
  .acsMainContainerFullPage--default dt,
  .acsMainContainerFullPage--default em,
  .acsMainContainerFullPage--default embed,
  .acsMainContainerFullPage--default fieldset,
  .acsMainContainerFullPage--default figcaption,
  .acsMainContainerFullPage--default figure,
  .acsMainContainerFullPage--default footer,
  .acsMainContainerFullPage--default form,
  .acsMainContainerFullPage--default h1,
  .acsMainContainerFullPage--default h2,
  .acsMainContainerFullPage--default h3,
  .acsMainContainerFullPage--default h4,
  .acsMainContainerFullPage--default h5,
  .acsMainContainerFullPage--default h6,
  .acsMainContainerFullPage--default header,
  .acsMainContainerFullPage--default hgroup,
  .acsMainContainerFullPage--default i,
  .acsMainContainerFullPage--default iframe,
  .acsMainContainerFullPage--default img,
  .acsMainContainerFullPage--default ins,
  .acsMainContainerFullPage--default kbd,
  .acsMainContainerFullPage--default label,
  .acsMainContainerFullPage--default legend,
  .acsMainContainerFullPage--default li,
  .acsMainContainerFullPage--default mark,
  .acsMainContainerFullPage--default menu,
  .acsMainContainerFullPage--default nav,
  .acsMainContainerFullPage--default object,
  .acsMainContainerFullPage--default ol,
  .acsMainContainerFullPage--default output,
  .acsMainContainerFullPage--default p,
  .acsMainContainerFullPage--default pre,
  .acsMainContainerFullPage--default q,
  .acsMainContainerFullPage--default ruby,
  .acsMainContainerFullPage--default s,
  .acsMainContainerFullPage--default samp,
  .acsMainContainerFullPage--default section,
  .acsMainContainerFullPage--default select,
  .acsMainContainerFullPage--default small,
  .acsMainContainerFullPage--default span,
  .acsMainContainerFullPage--default strike,
  .acsMainContainerFullPage--default strong,
  .acsMainContainerFullPage--default sub,
  .acsMainContainerFullPage--default summary,
  .acsMainContainerFullPage--default sup,
  .acsMainContainerFullPage--default table,
  .acsMainContainerFullPage--default tbody,
  .acsMainContainerFullPage--default td,
  .acsMainContainerFullPage--default tfoot,
  .acsMainContainerFullPage--default th,
  .acsMainContainerFullPage--default thead,
  .acsMainContainerFullPage--default time,
  .acsMainContainerFullPage--default tr,
  .acsMainContainerFullPage--default tt,
  .acsMainContainerFullPage--default u,
  .acsMainContainerFullPage--default ul,
  .acsMainContainerFullPage--default var,
  .acsMainContainerFullPage--default video,
  .acsMainContainerMobile--default a,
  .acsMainContainerMobile--default abbr,
  .acsMainContainerMobile--default acronym,
  .acsMainContainerMobile--default address,
  .acsMainContainerMobile--default applet,
  .acsMainContainerMobile--default article,
  .acsMainContainerMobile--default aside,
  .acsMainContainerMobile--default audio,
  .acsMainContainerMobile--default b,
  .acsMainContainerMobile--default big,
  .acsMainContainerMobile--default blockquote,
  .acsMainContainerMobile--default canvas,
  .acsMainContainerMobile--default caption,
  .acsMainContainerMobile--default center,
  .acsMainContainerMobile--default cite,
  .acsMainContainerMobile--default code,
  .acsMainContainerMobile--default dd,
  .acsMainContainerMobile--default del,
  .acsMainContainerMobile--default details,
  .acsMainContainerMobile--default dfn,
  .acsMainContainerMobile--default div,
  .acsMainContainerMobile--default dl,
  .acsMainContainerMobile--default dt,
  .acsMainContainerMobile--default em,
  .acsMainContainerMobile--default embed,
  .acsMainContainerMobile--default fieldset,
  .acsMainContainerMobile--default figcaption,
  .acsMainContainerMobile--default figure,
  .acsMainContainerMobile--default footer,
  .acsMainContainerMobile--default form,
  .acsMainContainerMobile--default h1,
  .acsMainContainerMobile--default h2,
  .acsMainContainerMobile--default h3,
  .acsMainContainerMobile--default h4,
  .acsMainContainerMobile--default h5,
  .acsMainContainerMobile--default h6,
  .acsMainContainerMobile--default header,
  .acsMainContainerMobile--default hgroup,
  .acsMainContainerMobile--default i,
  .acsMainContainerMobile--default iframe,
  .acsMainContainerMobile--default img,
  .acsMainContainerMobile--default ins,
  .acsMainContainerMobile--default kbd,
  .acsMainContainerMobile--default label,
  .acsMainContainerMobile--default legend,
  .acsMainContainerMobile--default li,
  .acsMainContainerMobile--default mark,
  .acsMainContainerMobile--default menu,
  .acsMainContainerMobile--default nav,
  .acsMainContainerMobile--default object,
  .acsMainContainerMobile--default ol,
  .acsMainContainerMobile--default output,
  .acsMainContainerMobile--default p,
  .acsMainContainerMobile--default pre,
  .acsMainContainerMobile--default q,
  .acsMainContainerMobile--default ruby,
  .acsMainContainerMobile--default s,
  .acsMainContainerMobile--default samp,
  .acsMainContainerMobile--default section,
  .acsMainContainerMobile--default select,
  .acsMainContainerMobile--default small,
  .acsMainContainerMobile--default span,
  .acsMainContainerMobile--default strike,
  .acsMainContainerMobile--default strong,
  .acsMainContainerMobile--default sub,
  .acsMainContainerMobile--default summary,
  .acsMainContainerMobile--default sup,
  .acsMainContainerMobile--default table,
  .acsMainContainerMobile--default tbody,
  .acsMainContainerMobile--default td,
  .acsMainContainerMobile--default tfoot,
  .acsMainContainerMobile--default th,
  .acsMainContainerMobile--default thead,
  .acsMainContainerMobile--default time,
  .acsMainContainerMobile--default tr,
  .acsMainContainerMobile--default tt,
  .acsMainContainerMobile--default u,
  .acsMainContainerMobile--default ul,
  .acsMainContainerMobile--default var,
  .acsMainContainerMobile--default video,
  .acsModalContainer--default a,
  .acsModalContainer--default abbr,
  .acsModalContainer--default acronym,
  .acsModalContainer--default address,
  .acsModalContainer--default applet,
  .acsModalContainer--default article,
  .acsModalContainer--default aside,
  .acsModalContainer--default audio,
  .acsModalContainer--default b,
  .acsModalContainer--default big,
  .acsModalContainer--default blockquote,
  .acsModalContainer--default canvas,
  .acsModalContainer--default caption,
  .acsModalContainer--default center,
  .acsModalContainer--default cite,
  .acsModalContainer--default code,
  .acsModalContainer--default dd,
  .acsModalContainer--default del,
  .acsModalContainer--default details,
  .acsModalContainer--default dfn,
  .acsModalContainer--default div,
  .acsModalContainer--default dl,
  .acsModalContainer--default dt,
  .acsModalContainer--default em,
  .acsModalContainer--default embed,
  .acsModalContainer--default fieldset,
  .acsModalContainer--default figcaption,
  .acsModalContainer--default figure,
  .acsModalContainer--default footer,
  .acsModalContainer--default form,
  .acsModalContainer--default h1,
  .acsModalContainer--default h2,
  .acsModalContainer--default h3,
  .acsModalContainer--default h4,
  .acsModalContainer--default h5,
  .acsModalContainer--default h6,
  .acsModalContainer--default header,
  .acsModalContainer--default hgroup,
  .acsModalContainer--default i,
  .acsModalContainer--default iframe,
  .acsModalContainer--default img,
  .acsModalContainer--default ins,
  .acsModalContainer--default kbd,
  .acsModalContainer--default label,
  .acsModalContainer--default legend,
  .acsModalContainer--default li,
  .acsModalContainer--default mark,
  .acsModalContainer--default menu,
  .acsModalContainer--default nav,
  .acsModalContainer--default object,
  .acsModalContainer--default ol,
  .acsModalContainer--default output,
  .acsModalContainer--default p,
  .acsModalContainer--default pre,
  .acsModalContainer--default q,
  .acsModalContainer--default ruby,
  .acsModalContainer--default s,
  .acsModalContainer--default samp,
  .acsModalContainer--default section,
  .acsModalContainer--default select,
  .acsModalContainer--default small,
  .acsModalContainer--default span,
  .acsModalContainer--default strike,
  .acsModalContainer--default strong,
  .acsModalContainer--default sub,
  .acsModalContainer--default summary,
  .acsModalContainer--default sup,
  .acsModalContainer--default table,
  .acsModalContainer--default tbody,
  .acsModalContainer--default td,
  .acsModalContainer--default tfoot,
  .acsModalContainer--default th,
  .acsModalContainer--default thead,
  .acsModalContainer--default time,
  .acsModalContainer--default tr,
  .acsModalContainer--default tt,
  .acsModalContainer--default u,
  .acsModalContainer--default ul,
  .acsModalContainer--default var,
  .acsModalContainer--default video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    text-transform: none;
    text-indent: 0;
    color: inherit;
    width: auto;
    float: none;
    background-image: none;
  }
  .acsMainContainerFullPage--default table,
  .acsMainContainerMobile--default table,
  .acsModalContainer--default table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  .acsMainContainerFullPage--default caption,
  .acsMainContainerFullPage--default td,
  .acsMainContainerFullPage--default th,
  .acsMainContainerMobile--default caption,
  .acsMainContainerMobile--default td,
  .acsMainContainerMobile--default th,
  .acsModalContainer--default caption,
  .acsModalContainer--default td,
  .acsModalContainer--default th {
    text-align: left;
    font-weight: 400;
    vertical-align: middle;
  }
  .acsMainContainerFullPage--default blockquote,
  .acsMainContainerFullPage--default q,
  .acsMainContainerMobile--default blockquote,
  .acsMainContainerMobile--default q,
  .acsModalContainer--default blockquote,
  .acsModalContainer--default q {
    quotes: none;
  }
  .acsMainContainerFullPage--default blockquote:after,
  .acsMainContainerFullPage--default blockquote:before,
  .acsMainContainerFullPage--default q:after,
  .acsMainContainerFullPage--default q:before,
  .acsMainContainerMobile--default blockquote:after,
  .acsMainContainerMobile--default blockquote:before,
  .acsMainContainerMobile--default q:after,
  .acsMainContainerMobile--default q:before,
  .acsModalContainer--default blockquote:after,
  .acsModalContainer--default blockquote:before,
  .acsModalContainer--default q:after,
  .acsModalContainer--default q:before {
    content: "";
    content: none;
  }
  .acsMainContainerFullPage--default a img,
  .acsMainContainerMobile--default a img,
  .acsModalContainer--default a img {
    border: none;
  }
  .acsMainContainerFullPage--default ol,
  .acsMainContainerFullPage--default ul,
  .acsMainContainerMobile--default ol,
  .acsMainContainerMobile--default ul,
  .acsModalContainer--default ol,
  .acsModalContainer--default ul {
    list-style: none;
  }
  .acsMainContainerFullPage--default .acs-feedback__button,
  .acsMainContainerMobile--default .acs-feedback__button,
  .acsModalContainer--default .acs-feedback__button {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5em 0.71429em;
    white-space: nowrap;
    -webkit-appearance: none;
    border-radius: 0;
    background-color: #3a475b;
    border: 1px solid #3a475b;
    cursor: pointer;
    color: #fff;
    font-size: 1.4em;
    text-align: center;
    background-image: none;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 2.85714em;
    width: 100%;
    max-width: 100%;
  }
  .acsMainContainerFullPage--default .acs-feedback__button--expand,
  .acsMainContainerMobile--default .acs-feedback__button--expand,
  .acsModalContainer--default .acs-feedback__button--expand {
    width: 100%;
  }
  .acsMainContainerFullPage--default .acs-feedback__button--epilogue,
  .acsMainContainerMobile--default .acs-feedback__button--epilogue,
  .acsModalContainer--default .acs-feedback__button--epilogue {
    margin-top: 2.857em;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .verint-sdk-star-rating:before,
  .acsMainContainerMobile--default .acs-feedback .verint-sdk-star-rating:before,
  .acsModalContainer--default .acs-feedback .verint-sdk-star-rating:before {
    background-image: none !important;
  }
  .acsMainContainerFullPage--default .verint-sdk-star-rating,
  .acsMainContainerMobile--default .verint-sdk-star-rating,
  .acsModalContainer--default .verint-sdk-star-rating {
    will-change: color;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-before: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;
    -webkit-padding-after: 0;
    background-image: none !important;
    background-color: transparent;
    height: auto;
    display: inline-block;
    width: 100% !important;
    line-height: 1;
    text-align: center;
  }
  .acsMainContainerFullPage--default
    .verint-sdk-star-rating
    .verint-sdk-star-rating__star,
  .acsMainContainerMobile--default
    .verint-sdk-star-rating
    .verint-sdk-star-rating__star,
  .acsModalContainer--default
    .verint-sdk-star-rating
    .verint-sdk-star-rating__star {
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  .acsMainContainerFullPage--default .verint-sdk-star-rating--disabled,
  .acsMainContainerMobile--default .verint-sdk-star-rating--disabled,
  .acsModalContainer--default .verint-sdk-star-rating--disabled {
    color: #bfc3c8;
  }
  .acsMainContainerFullPage--default
    .verint-sdk-star-rating--disabled
    .verint-sdk-star-rating__star,
  .acsMainContainerMobile--default
    .verint-sdk-star-rating--disabled
    .verint-sdk-star-rating__star,
  .acsModalContainer--default
    .verint-sdk-star-rating--disabled
    .verint-sdk-star-rating__star {
    cursor: default;
    border: 0;
  }
  .acsMainContainerFullPage--default .verint-sdk-star-rating label,
  .acsMainContainerMobile--default .verint-sdk-star-rating label,
  .acsModalContainer--default .verint-sdk-star-rating label {
    display: inline-block !important;
    font-size: inherit !important;
    margin: 0 4px;
    outline-width: medium;
    outline-color: #bfc3c8;
  }
  .acsMainContainerFullPage--default .verint-sdk-star-rating label:before,
  .acsMainContainerMobile--default .verint-sdk-star-rating label:before,
  .acsModalContainer--default .verint-sdk-star-rating label:before {
    position: inherit;
    font-size: inherit;
    transition: color 150ms ease;
    -webkit-transition: color 150ms ease;
    -moz-transition: color 150ms ease;
    -o-transition: color 150ms ease;
    width: 1em;
    border: none;
    box-shadow: none;
    background: 0 0 !important;
    font-family: "Material Icons - Verint No Conflict";
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    display: inherit;
    margin: 0;
    top: 0;
  }
  .acsMainContainerFullPage--default
    .verint-sdk-star-rating
    input[type="radio"],
  .acsMainContainerMobile--default .verint-sdk-star-rating input[type="radio"],
  .acsModalContainer--default .verint-sdk-star-rating input[type="radio"] {
    display: none;
  }
  .acsMainContainerFullPage--default
    .verint-sdk-star-rating
    input.verint-sdk-star-rating__star
    + label,
  .acsMainContainerMobile--default
    .verint-sdk-star-rating
    input.verint-sdk-star-rating__star
    + label,
  .acsModalContainer--default
    .verint-sdk-star-rating
    input.verint-sdk-star-rating__star
    + label {
    border: 0;
    cursor: pointer;
    display: inline-block;
    float: none !important;
    font-size: 4em;
    margin: 0;
    padding: 0;
    width: auto;
  }
  .acsMainContainerMobile
    .acsMainContainerFullPage--default
    .verint-sdk-star-rating
    input.verint-sdk-star-rating__star
    + label,
  .acsMainContainerMobile
    .acsMainContainerMobile--default
    .verint-sdk-star-rating
    input.verint-sdk-star-rating__star
    + label,
  .acsMainContainerMobile
    .acsModalContainer--default
    .verint-sdk-star-rating
    input.verint-sdk-star-rating__star
    + label {
    font-size: 5em !important;
  }
  .acsMainContainerFullPage--default .verint-sdk-star-rating label svg,
  .acsMainContainerMobile--default .verint-sdk-star-rating label svg,
  .acsModalContainer--default .verint-sdk-star-rating label svg {
    height: 40px;
    margin-left: -2px;
    pointer-events: none;
    stroke-width: 1;
    width: 40px;
  }
  .acsMainContainerFullPage--default
    .verint-sdk-star-rating
    label
    svg.verint-sdk-star-rating-svg__star-outline,
  .acsMainContainerMobile--default
    .verint-sdk-star-rating
    label
    svg.verint-sdk-star-rating-svg__star-outline,
  .acsModalContainer--default
    .verint-sdk-star-rating
    label
    svg.verint-sdk-star-rating-svg__star-outline {
    display: block;
    fill: #fff;
    stroke: #bfc3c8;
  }
  .acsMainContainerFullPage--default
    .verint-sdk-star-rating
    label
    svg.verint-sdk-star-rating-svg__star-fill,
  .acsMainContainerMobile--default
    .verint-sdk-star-rating
    label
    svg.verint-sdk-star-rating-svg__star-fill,
  .acsModalContainer--default
    .verint-sdk-star-rating
    label
    svg.verint-sdk-star-rating-svg__star-fill {
    display: none;
    fill: #ef6c00;
    stroke: #ef6c00;
  }
  .acsMainContainerFullPage--default
    .verint-sdk-star-rating
    label._acsHover
    svg.verint-sdk-star-rating-svg__star-outline,
  .acsMainContainerFullPage--default
    .verint-sdk-star-rating
    label.verint-sdk-star-rating__star--fill
    svg.verint-sdk-star-rating-svg__star-outline,
  .acsMainContainerMobile--default
    .verint-sdk-star-rating
    label._acsHover
    svg.verint-sdk-star-rating-svg__star-outline,
  .acsMainContainerMobile--default
    .verint-sdk-star-rating
    label.verint-sdk-star-rating__star--fill
    svg.verint-sdk-star-rating-svg__star-outline,
  .acsModalContainer--default
    .verint-sdk-star-rating
    label._acsHover
    svg.verint-sdk-star-rating-svg__star-outline,
  .acsModalContainer--default
    .verint-sdk-star-rating
    label.verint-sdk-star-rating__star--fill
    svg.verint-sdk-star-rating-svg__star-outline {
    display: none;
  }
  .acsMainContainerFullPage--default
    .verint-sdk-star-rating
    label._acsHover
    svg.verint-sdk-star-rating-svg__star-fill,
  .acsMainContainerFullPage--default
    .verint-sdk-star-rating
    label.verint-sdk-star-rating__star--fill
    svg.verint-sdk-star-rating-svg__star-fill,
  .acsMainContainerMobile--default
    .verint-sdk-star-rating
    label._acsHover
    svg.verint-sdk-star-rating-svg__star-fill,
  .acsMainContainerMobile--default
    .verint-sdk-star-rating
    label.verint-sdk-star-rating__star--fill
    svg.verint-sdk-star-rating-svg__star-fill,
  .acsModalContainer--default
    .verint-sdk-star-rating
    label._acsHover
    svg.verint-sdk-star-rating-svg__star-fill,
  .acsModalContainer--default
    .verint-sdk-star-rating
    label.verint-sdk-star-rating__star--fill
    svg.verint-sdk-star-rating-svg__star-fill {
    display: block;
  }
  .acsMainContainerFullPage--default .acs-feedback__cbox label,
  .acsMainContainerFullPage--default .acs-feedback__radio label,
  .acsMainContainerMobile--default .acs-feedback__cbox label,
  .acsMainContainerMobile--default .acs-feedback__radio label,
  .acsModalContainer--default .acs-feedback__cbox label,
  .acsModalContainer--default .acs-feedback__radio label {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    cursor: pointer;
    -ms-align-items: center;
    align-items: center;
    height: 2.57143em;
    margin-bottom: -1px;
    color: #3a475b;
  }
  .acsMainContainerFullPage--default .acs-feedback__cbox label input,
  .acsMainContainerFullPage--default .acs-feedback__radio label input,
  .acsMainContainerMobile--default .acs-feedback__cbox label input,
  .acsMainContainerMobile--default .acs-feedback__radio label input,
  .acsModalContainer--default .acs-feedback__cbox label input,
  .acsModalContainer--default .acs-feedback__radio label input {
    visibility: hidden;
    left: -999999px;
    opacity: 0;
    position: absolute;
  }
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper {
    flex: 0 0 16px;
    margin-left: 4px;
    margin-right: 8px;
  }
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg {
    fill: #3a475b;
    pointer-events: none;
  }
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked {
    height: 20px;
    width: 20px;
  }
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerFullPage--default .acs-feedback__radio label svg,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerMobile--default .acs-feedback__radio label svg,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsModalContainer--default .acs-feedback__radio label svg {
    height: 16px;
    width: 16px;
  }
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__off,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__unchecked,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__off {
    display: block;
  }
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-radio__off,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-radio__off,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-radio__off,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-cbradio__unchecked,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-radio__off,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsModalContainer--default
    .acs-feedback__cbox
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsModalContainer--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-cbradio__unchecked,
  .acsModalContainer--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-radio__off,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-cbox-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-cbradio__checked,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .verint-sdk-radio-svg-wrapper
    svg.acs-feedback-radio__on,
  .acsModalContainer--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-cbradio__unchecked,
  .acsModalContainer--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-radio__off {
    display: none;
  }
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-radio__on,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-radio__on,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-radio__on,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-cbradio__checked,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-radio__on,
  .acsModalContainer--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-cbradio__checked,
  .acsModalContainer--default
    .acs-feedback__cbox
    label.acsChecked
    svg.acs-feedback-radio__on,
  .acsModalContainer--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-cbradio__checked,
  .acsModalContainer--default
    .acs-feedback__radio
    label.acsChecked
    svg.acs-feedback-radio__on {
    display: block;
  }
  .acsMainContainerFullPage--default
    .acs-feedback__cbox
    label
    .acs-feedback-cb__text,
  .acsMainContainerFullPage--default
    .acs-feedback__radio
    label
    .acs-feedback-cb__text,
  .acsMainContainerMobile--default
    .acs-feedback__cbox
    label
    .acs-feedback-cb__text,
  .acsMainContainerMobile--default
    .acs-feedback__radio
    label
    .acs-feedback-cb__text,
  .acsModalContainer--default .acs-feedback__cbox label .acs-feedback-cb__text,
  .acsModalContainer--default
    .acs-feedback__radio
    label
    .acs-feedback-cb__text {
    flex: 1;
  }
  .acsMainContainerFullPage--default .acs-feedback,
  .acsMainContainerMobile--default .acs-feedback,
  .acsModalContainer--default .acs-feedback {
    font-size: 10px;
    padding-left: 2em;
    padding-right: 2em;
    line-height: 1.618;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: left;
    color: #3a475b;
  }
  @media (min-width: 1024px) {
    .acsMainContainerFullPage--default .acs-feedback,
    .acsMainContainerMobile--default .acs-feedback,
    .acsModalContainer--default .acs-feedback {
      text-align: left;
    }
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__heading,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__heading,
  .acsModalContainer--default .acs-feedback .acs-feedback__heading {
    margin: 0;
    line-height: 1;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__heading--h1,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__heading--h1,
  .acsModalContainer--default .acs-feedback .acs-feedback__heading--h1 {
    padding-top: 0.938em;
    font-size: 1.6em;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__heading--h2,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__heading--h2,
  .acsModalContainer--default .acs-feedback .acs-feedback__heading--h2 {
    font-size: 2.4em;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__block,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__block,
  .acsModalContainer--default .acs-feedback .acs-feedback__block {
    padding: 0.5em;
    margin-top: 1em;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__block--epilogue
    .acs-feedback__heading--h1,
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__block.acs-feedback--keyline.acs-persistent__block,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__block--epilogue
    .acs-feedback__heading--h1,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__block.acs-feedback--keyline.acs-persistent__block,
  .acsModalContainer--default
    .acs-feedback
    .acs-feedback__block--epilogue
    .acs-feedback__heading--h1,
  .acsModalContainer--default
    .acs-feedback
    .acs-feedback__block.acs-feedback--keyline.acs-persistent__block {
    padding-top: 0;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__block--confirmation,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__block--confirmation,
  .acsModalContainer--default .acs-feedback .acs-feedback__block--confirmation {
    text-align: center;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__block--hidden,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__block--hidden,
  .acsModalContainer--default .acs-feedback .acs-feedback__block--hidden {
    display: none;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__block--epilogue
    p,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__block--epilogue
    p,
  .acsModalContainer--default .acs-feedback .acs-feedback__block--epilogue p {
    padding-top: 1em;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-starrating__block.acs-persistent__block
    .acs-feedback__label,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-starrating__block.acs-persistent__block
    .acs-feedback__label,
  .acsModalContainer--default
    .acs-feedback
    .acs-starrating__block.acs-persistent__block
    .acs-feedback__label {
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    padding-bottom: 0.5em;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-starrating__block.acs-persistent__block
    .verint-sdk-star-rating,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-starrating__block.acs-persistent__block
    .verint-sdk-star-rating,
  .acsModalContainer--default
    .acs-feedback
    .acs-starrating__block.acs-persistent__block
    .verint-sdk-star-rating {
    text-align: center;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback--keyline,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback--keyline,
  .acsModalContainer--default .acs-feedback .acs-feedback--keyline {
    padding-top: 0;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__block--epilogue,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__block--epilogue,
  .acsModalContainer--default .acs-feedback .acs-feedback__block--epilogue {
    padding-top: 4em;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__label,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__label,
  .acsModalContainer--default .acs-feedback .acs-feedback__label {
    font-size: 120%;
    padding-bottom: 0.95em;
    margin-bottom: 0.3em;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    color: inherit;
    display: block;
    text-align: left;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__label p,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__label p,
  .acsModalContainer--default .acs-feedback .acs-feedback__label p {
    margin: 0;
    padding: 0;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__label--sublabel,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__label--sublabel,
  .acsModalContainer--default .acs-feedback .acs-feedback__label--sublabel {
    font-weight: 400;
  }
  .acsMainContainerFullPage--default .acs-feedback p,
  .acsMainContainerMobile--default .acs-feedback p,
  .acsModalContainer--default .acs-feedback p {
    font-size: 1.2em;
    line-height: 1.2;
    margin: 0;
  }
  .acsMainContainerFullPage--default .acs-feedback em,
  .acsMainContainerMobile--default .acs-feedback em,
  .acsModalContainer--default .acs-feedback em {
    font-style: italic;
  }
  .acsMainContainerFullPage--default .acs-feedback a:link,
  .acsMainContainerMobile--default .acs-feedback a:link,
  .acsModalContainer--default .acs-feedback a:link {
    color: #11a2f3;
    text-decoration: underline;
  }
  .acsMainContainerFullPage--default .acs-feedback a:hover,
  .acsMainContainerMobile--default .acs-feedback a:hover,
  .acsModalContainer--default .acs-feedback a:hover {
    color: #367aeb;
  }
  .acsMainContainerFullPage--default .acs-feedback a:visited,
  .acsMainContainerMobile--default .acs-feedback a:visited,
  .acsModalContainer--default .acs-feedback a:visited {
    color: #124b91;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback--l-align,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback--l-align,
  .acsModalContainer--default .acs-feedback .acs-feedback--l-align {
    float: left;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback--r-align,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback--r-align,
  .acsModalContainer--default .acs-feedback .acs-feedback--r-align {
    float: right;
  }
  .acsMainContainerFullPage--default .acs-feedback--center,
  .acsMainContainerMobile--default .acs-feedback--center,
  .acsModalContainer--default .acs-feedback--center {
    padding-top: 1.5em;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback--keyline,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback--keyline,
  .acsModalContainer--default .acs-feedback .acs-feedback--keyline {
    text-align: center;
    font-weight: 700;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback--keyline
    > :empty,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback--keyline
    > :empty,
  .acsModalContainer--default .acs-feedback .acs-feedback--keyline > :empty {
    display: none;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback--keyline
    .acs-feedback__logo,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback--keyline
    .acs-feedback__logo,
  .acsModalContainer--default
    .acs-feedback
    .acs-feedback--keyline
    .acs-feedback__logo {
    max-height: 8em !important;
    max-width: 16em;
    margin-right: auto;
    margin-left: auto;
    display: block;
    padding-top: 3.9em;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback--keyline p,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback--keyline p,
  .acsModalContainer--default .acs-feedback .acs-feedback--keyline p {
    font-size: 1.4em;
    padding-top: 1.071em;
    line-height: 1;
    color: inherit;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    display: block;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__control,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__control,
  .acsModalContainer--default .acs-feedback .acs-feedback__control {
    position: relative;
    border: 1px solid #bfc3c8;
    border-radius: 0;
    box-sizing: border-box;
    font-size: 140%;
    height: auto;
    padding-bottom: 0.3em;
    padding-top: 0.3em;
    width: 100% !important;
    resize: none;
    text-align: left;
    -webkit-appearance: none;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__control.input--focus,
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__control:active,
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__control:hover,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__control.input--focus,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__control:active,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__control:hover,
  .acsModalContainer--default .acs-feedback .acs-feedback__control.input--focus,
  .acsModalContainer--default .acs-feedback .acs-feedback__control:active,
  .acsModalContainer--default .acs-feedback .acs-feedback__control:hover {
    border: 1px solid #627085;
    z-index: 2000;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__control--textarea,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__control--textarea,
  .acsModalContainer--default .acs-feedback .acs-feedback__control--textarea {
    height: 7em;
    padding: 0.5em;
    border: 1px solid #bfc3c8;
    color: #627085;
    margin: 0;
    background-color: #fff !important;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__control--textinput,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__control--textinput,
  .acsModalContainer--default .acs-feedback .acs-feedback__control--textinput {
    height: 2.571em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    background-color: #fff !important;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__textarea,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__textarea,
  .acsModalContainer--default .acs-feedback .acs-feedback__textarea {
    position: relative;
    text-align: center;
    line-height: 1;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__textarea--count,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__textarea--count,
  .acsModalContainer--default .acs-feedback .acs-feedback__textarea--count {
    display: block;
    text-align: right;
    float: right;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-feedback__select,
  .acsMainContainerMobile--default .acs-feedback .acs-feedback__select,
  .acsModalContainer--default .acs-feedback .acs-feedback__select {
    color: #313943;
    max-width: none;
    padding-top: 0;
    height: 2.57143em;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__select-button,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__select-button,
  .acsModalContainer--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__select-button {
    padding-top: 5px;
    padding-left: 10px;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__select
    span:after,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__select
    span:after,
  .acsModalContainer--default .acs-feedback .acs-feedback__select span:after {
    cursor: pointer;
    position: absolute;
    font-family: "Material Icons - Verint No Conflict";
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    content: "";
    top: 10%;
    right: 0.1em;
    color: inherit;
    font-size: 2em;
    z-index: 0;
    opacity: 1;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__select--opened
    span:after,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__select--opened
    span:after,
  .acsModalContainer--default
    .acs-feedback
    .acs-feedback__select--opened
    span:after {
    content: "";
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__selector,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__selector,
  .acsModalContainer--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__selector {
    display: inherit !important;
    background: 0 0 !important;
    border-radius: 0;
    color: #627085;
    cursor: pointer;
    font-size: 1em;
    height: 3.6em;
    left: 0;
    max-width: none;
    padding: 0.5em 2.3em 0.5em 0.5em;
    position: absolute;
    top: 0 !important;
    z-index: 5;
    opacity: 1;
    width: 100%;
    text-transform: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__selector::-ms-expand,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__selector::-ms-expand,
  .acsModalContainer--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__selector::-ms-expand {
    display: none;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__selector
    option,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__selector
    option,
  .acsModalContainer--default
    .acs-feedback
    .acs-feedback__select
    .acs-feedback__selector
    option {
    font-size: 1em;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-footer,
  .acsMainContainerMobile--default .acs-feedback .acs-footer,
  .acsModalContainer--default .acs-feedback .acs-footer {
    display: block;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-footer .acs-powered img,
  .acsMainContainerMobile--default .acs-feedback .acs-footer .acs-powered img,
  .acsModalContainer--default .acs-feedback .acs-footer .acs-powered img {
    margin-top: -5px;
    height: 2em;
    float: right;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-footer
    .acs-footer--left,
  .acsMainContainerMobile--default .acs-feedback .acs-footer .acs-footer--left,
  .acsModalContainer--default .acs-feedback .acs-footer .acs-footer--left {
    float: left;
    padding-bottom: 1em;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    a,
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    a:active,
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    a:hover,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    a,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    a:active,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    a:hover,
  .acsModalContainer--default .acs-feedback .acs-footer .acs-footer--left a,
  .acsModalContainer--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    a:active,
  .acsModalContainer--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    a:hover {
    color: #464c55;
    text-decoration: none;
  }
  .acsMainContainerFullPage--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    .acs-required,
  .acsMainContainerMobile--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    .acs-required,
  .acsModalContainer--default
    .acs-feedback
    .acs-footer
    .acs-footer--left
    .acs-required {
    color: #f24554;
    margin-left: 0;
    text-align: left;
    display: none;
  }
  .acsMainContainerFullPage--default .acs-feedback__topic,
  .acsMainContainerMobile--default .acs-feedback__topic,
  .acsModalContainer--default .acs-feedback__topic {
    display: none;
  }
  .acsMainContainerFullPage--default .acs-feedback__topic.acs-visible__topic,
  .acsMainContainerMobile--default .acs-feedback__topic.acs-visible__topic,
  .acsModalContainer--default .acs-feedback__topic.acs-visible__topic {
    display: block;
  }
  .acsMainContainerFullPage--default div.acs-feedback-banner-img-container,
  .acsMainContainerMobile--default div.acs-feedback-banner-img-container,
  .acsModalContainer--default div.acs-feedback-banner-img-container {
    overflow: hidden;
    max-height: 9.375em;
    position: relative;
  }
  .acsMainContainerFullPage--default
    div.acs-feedback-banner-img-container
    img.acs-feedback-banner-img,
  .acsMainContainerMobile--default
    div.acs-feedback-banner-img-container
    img.acs-feedback-banner-img,
  .acsModalContainer--default
    div.acs-feedback-banner-img-container
    img.acs-feedback-banner-img {
    width: 100%;
  }
  .acsMainContainerFullPage--default .acs-clear,
  .acsMainContainerMobile--default .acs-clear,
  .acsModalContainer--default .acs-clear {
    clear: both;
  }
  .acsModalContainer--default {
    width: 100%;
    padding: 0;
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 2147483647;
  }
  .acsModalContainer--default .acs-loader {
    width: 1.5em;
    height: 1.5em;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    z-index: 2147483648;
  }
  .acsModalContainer--default .acs-feedback .acs-validation-block {
    background-color: #fef1f2;
    color: #626262;
    border: 2px solid #a5101d;
    padding: 0.4em;
    font-weight: 700;
    font-size: 1.3em;
    border-radius: 0.5em;
    margin-bottom: 0.5em;
    display: none;
    text-align: center;
  }
  .acsModalContainer--default .acs-feedback__block--invalid {
    background-color: #fef1f2;
    border: 2px solid #a5101d;
    font-weight: 700;
    border-radius: 0.5em !important;
  }
  .acsModalContainer--default .acs-feedback__block--required {
    text-align: right;
    color: #a5101d;
  }
  .acsModalContainer--default .acs-feedback__block--optional {
    text-align: right;
  }
  .acsModalContainer--default .acsModalInnerContent .acs-serviceunavailable {
    padding-top: 1.07143em;
    padding-bottom: 1.07143em;
    text-align: center;
  }
  .acsModalContainer--default .acsModalInnerContent .acs-serviceunavailable p {
    font-size: 1.5em;
    margin-bottom: 0.2em;
  }
  .acsModalContainer--default
    .acsModalInnerContent
    .acs-serviceunavailable--flashIcon {
    font-size: 3.5em;
    color: #f24554;
    background: 0 0 !important;
  }
  .acsModalContainer--default
    .acsModalInnerContent
    .acs-serviceunavailable
    .acs-serviceunavailable__heading {
    font-size: 3em;
    margin-bottom: 0.5em;
    color: #f24554;
  }
  .acsModalContainer--default .acsModalBackFace {
    background: #343941;
    width: 100%;
    padding: 0;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 888;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .acsModalContainer--default .acsModalBackFace._acsActive {
    opacity: 0.85;
  }
  .acsModalContainer--default .acsModalBackFace._acsFixed {
    position: fixed !important;
  }
  .acsModalContainer--default .acsModalChrome {
    padding: 0;
    margin: 0;
    z-index: 1000;
    position: fixed;
    height: 100vh;
    overflow: auto;
    left: -20px;
    right: -20px;
  }
  .acsModalContainer--default .acsModalChrome .acsModalContent {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    width: 450px;
    margin: 0 auto;
    text-align: left;
    padding: 10px;
  }
  .acsModalContainer--default
    .acsModalChrome
    .acsModalContent
    input[type="text"],
  .acsModalContainer--default .acsModalChrome .acsModalContent textarea {
    margin: 0;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    box-sizing: border-box;
  }
  .acsMainContainerFullPage--default .acs-serviceunavailable .flashIcon:before,
  .acsMainContainerMobile--default .acs-serviceunavailable .flashIcon:before,
  .acsModalContainer--default
    .acsModalChrome
    .acsModalContent
    .acsModalInnerContent
    .flashIcon:before {
    font-family: "Material Icons - Verint No Conflict";
    font-style: normal;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    content: "";
  }
  .acsModalContainer--default .acsModalChrome .acsModalContent .acsModalHead {
    width: 100%;
    display: block;
    clear: both;
    height: 20px;
    margin-bottom: 5px;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .acsModalContainer--default
    .acsModalChrome
    .acsModalContent
    .acsModalHead.acsVisible {
    opacity: 1;
  }
  .acsModalContainer--default
    .acsModalChrome
    .acsModalContent
    .acsModalHead
    .acsModalCloseButton {
    width: 1em !important;
    height: 1em !important;
    float: right;
  }
  .acsModalContainer--default
    .acsModalChrome
    .acsModalContent
    .acsModalHead
    .acsModalCloseButton:hover {
    cursor: pointer;
  }
  .acsModalContainer--default
    .acsModalChrome
    .acsModalContent
    .acsModalInnerContent {
    clear: both;
    background: #fff;
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .acsModalContainer--default
    .acsModalChrome
    .acsModalContent
    .acsModalInnerContent.acsVisible {
    opacity: 0.999999;
  }
  .acsModalContainer--default
    .acsModalChrome
    .acsModalContent
    .acsModalInnerContent
    .flashIcon:before {
    font-weight: 400;
    display: inline-block;
  }
  .acsIE10.acsModalContainer--default .acs-feedback__cbradio {
    padding-top: 0.25em;
    min-height: 2em;
    display: inline-block;
    padding-left: 0.5em;
  }
  .acsIE10.acsModalContainer--default .acs-feedback__cbradio input {
    display: inline;
    left: auto;
    margin-right: 0.5em;
    opacity: 1;
    position: relative;
    visibility: visible;
  }
  .acsIE10.acsModalContainer--default .acs-feedback__cbradio:before {
    display: none;
  }
  .acsMainContainerFullPage--default,
  .acsMainContainerMobile--default {
    max-width: 34em;
    margin-left: auto;
    margin-right: auto;
    display: none;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
  }
  .acsMainContainerFullPage--default div.acs-feedback-banner-img-container,
  .acsMainContainerMobile--default div.acs-feedback-banner-img-container {
    padding-left: 2em;
    padding-right: 2em;
  }
  .acsVisible.acsMainContainerFullPage--default,
  .acsVisible.acsMainContainerMobile--default {
    display: block;
  }
  .acsMainContainerFullPage--default .acs-loader,
  .acsMainContainerMobile--default .acs-loader {
    width: 1.5em;
    height: 1.5em;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    z-index: 2147483648;
  }
  .acsMainContainerFullPage--default .acs-feedback .acs-validation-block,
  .acsMainContainerMobile--default .acs-feedback .acs-validation-block {
    background-color: #fef1f2;
    color: #626262;
    border: 2px solid #a5101d;
    padding: 0.4em;
    font-weight: 700;
    font-size: 1.3em;
    border-radius: 0.5em;
    margin-bottom: 0.5em;
    display: none;
    text-align: center;
  }
  .acsMainContainerFullPage--default .acs-feedback__block--invalid,
  .acsMainContainerMobile--default .acs-feedback__block--invalid {
    background-color: #fef1f2;
    border: 2px solid #a5101d;
    font-weight: 700;
    border-radius: 0.5em !important;
  }
  .acsMainContainerFullPage--default .acs-feedback__block--required,
  .acsMainContainerMobile--default .acs-feedback__block--required {
    text-align: right;
    color: #a5101d;
  }
  .acsMainContainerFullPage--default .acs-feedback__block--optional,
  .acsMainContainerMobile--default .acs-feedback__block--optional {
    text-align: right;
  }
  .acsMainContainerFullPage--default .acs-serviceunavailable,
  .acsMainContainerMobile--default .acs-serviceunavailable {
    padding-top: 1.07143em;
    padding-bottom: 1.07143em;
    text-align: center;
  }
  .acsMainContainerFullPage--default .acs-serviceunavailable .flashIcon,
  .acsMainContainerMobile--default .acs-serviceunavailable .flashIcon {
    font-size: 3.5em;
    color: #f24554;
    background: 0 0 !important;
  }
  .acsMainContainerFullPage--default .acs-serviceunavailable .flashIcon:before,
  .acsMainContainerMobile--default .acs-serviceunavailable .flashIcon:before {
    font-weight: 400;
    display: inline-block;
  }
  .acsMainContainerFullPage--default .acs-serviceunavailable p,
  .acsMainContainerMobile--default .acs-serviceunavailable p {
    font-size: 1.5em;
    margin-bottom: 0.2em;
  }
  .acsMainContainerFullPage--default
    .acs-serviceunavailable
    .acs-serviceunavailable__heading,
  .acsMainContainerMobile--default
    .acs-serviceunavailable
    .acs-serviceunavailable__heading {
    font-size: 3em;
    margin-bottom: 0.5em;
    color: #f24554;
  }
  .acsIE10.acsMainContainerFullPage--default .acs-feedback__cbradio,
  .acsIE10.acsMainContainerMobile--default .acs-feedback__cbradio {
    padding-top: 0.25em;
    min-height: 2em;
    display: inline-block;
    padding-left: 0.5em;
  }
  .acsIE10.acsMainContainerFullPage--default .acs-feedback__cbradio input,
  .acsIE10.acsMainContainerMobile--default .acs-feedback__cbradio input {
    display: inline;
    left: auto;
    margin-right: 0.5em;
    opacity: 1;
    position: relative;
    visibility: visible;
  }
  .acsIE10.acsMainContainerFullPage--default .acs-feedback__cbradio:before,
  .acsIE10.acsMainContainerMobile--default .acs-feedback__cbradio:before {
    display: none;
  }
  .acsMainContainerFullPage--default {
    margin-top: 2em;
  }
  .acsMainContainerFullPage--default .acsModalCloseButton {
    transition: 0.5s;
    width: 1em !important;
    height: 1em !important;
    float: right;
    opacity: 0.5;
    margin-top: -1.5em;
    padding: 0.2em;
    background: #3a475b;
  }
  .acsMainContainerFullPage--default .acsModalCloseButton:hover {
    cursor: pointer;
    opacity: 1;
  }
  ._2doJaa6tm9-JRQ8xqJYmEY {
    margin-bottom: 15px;
  }
  .card {
    border: none !important;
  }

  a {
    text-decoration: none !important;
  }
  hr {
    opacity: 100% !important;
    background-color: #c00 !important;
  }

  ._2Ju7tI5BQiBHPB1duE53R9:hover {
    color: #fff;
  }

  .EHhj06HcZVODX16UezPHh:hover {
    color: #fff;
  }

